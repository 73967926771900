<template>
    <div>
        <!-- Page Header -->
        <section class="bg-dark pt-5 pb-4">
            <div class="container py-5">
                <h1 class="text-light pt-1 pt-md-3 mb-4">
                    {{ panelLink ? panelLink.panel_configuration.display_name : 'Loading...' }}
                </h1>
            </div>
        </section>
        <!-- / Page Header -->

        <!-- Results -->
        <section class="position-relative bg-white rounded-xxl-4 zindex-5" style="margin-top: -30px;">
            <div class="container pt-4 pb-5 mb-md-4">
                <!-- Breadcrumb-->
                <breadcrumb :crumbs="crumbs" class="pb-4 my-2"></breadcrumb>

                <!-- Is Loading -->
                <div v-if="isLoading" class="text-center">
                    <spinner></spinner>
                </div>
                <!-- / Is Loading -->

                <!-- No panel link -->
                <div v-if="!isLoading && !panelLink" class="text-center">
                    <p>Nothing to show here.</p>
                </div>
                <!-- / No panel link -->

                <!-- Inside -->
                <div class="inside">

                    <div class="row">
                        <div class="position-relative pe-lg-5 mb-5 mb-lg-0 col-lg-7 col-12" style="z-index: 1025;">
                            <div class="d-flex justify-content-between mb-2">
                                <!-- <h2 class="h3 mb-0">Partnership Manager</h2> -->
                                <!-- <div class="text-end"><span
                                        class="fs-sm mb-2 badge rounded-pill bg-faded-accent">Featured</span>
                                    <div class="fs-sm text-muted">2 hours ago</div>
                                </div> -->
                            </div>
                            <ul class="list-unstyled fs-sm mb-4">
                                <!-- <li class="mb-2"><a class="d-flex align-items-center text-decoration-none"
                                        href="#"><i class="fi-external-link me-2"></i><span
                                            class="text-decoration-underline">View Website</span></a></li> -->
                                <li v-if="panelLink && panelLink.panel_configuration && panelLink.panel_configuration.location"
                                    class="d-flex align-items-center mb-2"><i
                                        class="fi-map-pin text-muted me-2"></i><span>{{
                                            panelLink.panel_configuration.location }}</span></li>
                                <li v-if="panelLink && panelLink.panel_configuration && panelLink.panel_configuration.pricing_note"
                                    class="d-flex align-items-center mb-2"><i
                                        class="fi-cash fs-base text-muted me-2"></i><span>{{
                                            panelLink.panel_configuration.pricing_note }}</span></li>
                                <!-- <li class="d-flex align-items-center mb-2"><i class="fi-phone text-muted me-2"></i><span
                                        class="me-2">Bessie Cooper, HR Manager</span><a href="#">Show phone</a></li>
                                <li class="d-flex align-items-center mb-2"><i class="fi-clock text-muted me-2"></i><span
                                        class="me-2">Full-time</span></li> -->
                            </ul>
                            <hr class="mb-4">
                            <h3 class="h6">A Bit About Us</h3>
                            <p>
                                {{ panelLink ? panelLink.panel_configuration.about_us : 'Loading...' }}
                            </p>
                            <h3 class="h6 pt-4">Meet Some Of Our Professionals</h3>
                            <!-- <h3 class="h6 pt-2">Company offers:</h3>
                            <ul class="list-unstyled">
                                <li class="d-flex"><span class="text-primary fs-lg me-2">•</span>Et mattis sit imperdiet
                                    risus, fermentum ultrices non.</li>
                                <li class="d-flex"><span class="text-primary fs-lg me-2">•</span>Sed phasellus consequat
                                    nunc enim interdum tristique ultrices egestas.</li>
                                <li class="d-flex"><span class="text-primary fs-lg me-2">•</span>Molestie mus ultrices
                                    tellus enim.</li>
                                <li class="d-flex"><span class="text-primary fs-lg me-2">•</span>Vitae pulvinar massa id
                                    lorem aliquam fermentum egestas.</li>
                                <li class="d-flex"><span class="text-primary fs-lg me-2">•</span>Dictumst blandit sit mauris
                                    in consectetur.</li>
                                <li class="d-flex"><span class="text-primary fs-lg me-2">•</span>Sem purus lorem odio donec
                                    faucibus ipsum ultricies.</li>
                            </ul> -->

                            <!-- <p class="pt-2 mb-1"> -->
                            <!-- {{ panelLink ? panelLink.panel_configuration.user_roles : 'Loading...' }} -->
                            <!-- </p> -->

                            <div class="row">
                                <div class="col-lg-6" v-for="userRole in panelLink.panel_configuration.user_roles"
                                    :key="`panel-lawyer-${userRole.id}`">
                                    <div class="mb-3">
                                        <panel-lawyer-card-and-bio :user-role="userRole"></panel-lawyer-card-and-bio>
                                    </div>
                                </div>
                            </div>

                            <!-- <p class="pt-2 mb-1">Please, send your CV marked “Partnership Manager” in the subject via
                                e-mail:</p><a class="nav-link-muted fw-bold"
                                href="mailto:contact@example.com">contact@example.com</a> -->
                            <hr class="my-4">
                            <!-- <div role="group" class="btn-group btn-group-lg"><button type="button"
                                    class="rounded-pill rounded-end-0 ps-4 pe-3 btn btn-primary">Click here to start a
                                    conversation</button><button type="button"
                                    class="border-end-0 border-top-0 border-bottom-0 border-light rounded-pill rounded-start-0 px-3 btn btn-primary"><i
                                        class="fi-messenger me-1"></i></button></div> -->

                            <panel-start-conversation-button-and-modal-unauthenticated-secondary
                                v-if="!user"
                                :panel-configuration="panelLink.panel_configuration"></panel-start-conversation-button-and-modal-unauthenticated-secondary>

                            <panel-start-conversation-button-and-modal
                                v-if="user" type="secondary"
                                :panel-configuration="panelLink.panel_configuration">
                            </panel-start-conversation-button-and-modal>

                        </div>
                        <aside class="col-lg-5 col-12" style="margin-top: -6rem;">
                            <div class="sticky-top" style="padding-top: 6rem;">
                                <div class="shadow-sm mb-3 mb-lg-0 card">
                                    <div class="card-body">
                                        <h2 class="h3 mb-4">Pricing Estimates</h2>

                                        <!-- Fixed Prices -->
                                        <div v-if="fixedPrices && fixedPrices.length">
                                            <h6>Fixed Prices</h6>
                                            <table class="table table-responsive">
                                                <thead>
                                                    <tr>
                                                        <th>Description</th>
                                                        <th>Guideline Price (Ex VAT)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(fixedPrice, i) in fixedPrices"
                                                        :key="`fixed-price-${i}`">
                                                        <td>{{ fixedPrice.service }}</td>
                                                        <td>{{ formatCostInPence2dp(fixedPrice.lower_price) }} <span
                                                                v-if="fixedPrice.upper_price"> - {{
                                                                    formatCostInPence2dp(fixedPrice.upper_price) }}</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!-- / Fixed Prices -->

                                        <!-- Hourly Prices -->
                                        <div v-if="hourlyPrices && hourlyPrices.length"
                                            :class="{ 'mt-5': (hourlyPrices.length && fixedPrices.length) }">
                                            <h6>Hourly Prices</h6>
                                            <table class="table table-responsive">
                                                <thead>
                                                    <tr>
                                                        <th>Description</th>
                                                        <th>Guideline Price (Ex VAT)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(hourlyPrice, i) in hourlyPrices"
                                                        :key="`hourly-price-${i}`">
                                                        <td>{{ hourlyPrice.service }}</td>
                                                        <td>{{ formatCostInPence2dp(hourlyPrice.lower_price) }} <span
                                                                v-if="hourlyPrice.upper_price"> - {{
                                                                    formatCostInPence2dp(hourlyPrice.upper_price) }}</span>
                                                            /
                                                            hour</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!-- / Hourly Prices -->

                                        <!-- <hr class="my-4">
                                        <div class="d-flex align-items-end">
                                            <div class="fs-lg fw-bold me-3">Like our jobs?<br>Receive vacancies in Telegram
                                            </div><a href="#"
                                                class="btn btn-icon btn-xs rounded-circle btn-translucent-primary"><i
                                                    class="fi-telegram"></i></a>
                                        </div> -->
                                    </div>
                                </div>

                            </div>
                        </aside>
                    </div>

                </div>
                <!-- / Inside -->

                <!-- {{ panelLink }} -->

            </div>
        </section>
        <!-- / Results -->

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from '../../components/Spinner.vue'
import axios from 'axios';
import Display from '../../mixins/Display';
import Breadcrumb from '../../components/Breadcrumb.vue';
import PanelLawyerCardAndBio from '../../components/PanelConfiguration/PanelLawyerCardAndBio.vue';
import _ from 'lodash';
import PanelStartConversationButtonAndModalUnauthenticatedSecondary from '../../components/PanelConfiguration/PanelStartConversationButtonAndModalUnauthenticatedSecondary.vue';
import PanelStartConversationButtonAndModal from '../../components/PanelConfiguration/PanelStartConversationButtonAndModal.vue';

export default {
    data() {
        return {
            isLoading: false,
            panelLink: null
        }
    },

    mixins: [Display],

    components: { Spinner, Breadcrumb, PanelLawyerCardAndBio, PanelStartConversationButtonAndModalUnauthenticatedSecondary, PanelStartConversationButtonAndModal },

    computed: {
        ...mapGetters('whitelabelConfiguration', {
            whitelabelConfiguration: 'whitelabelConfiguration'
        }),

        ...mapGetters('auth', {
            user: 'user'
        }),

        crumbs() {
            return [
                {
                    href: "/landing",
                    label: this.whitelabelConfiguration ? this.whitelabelConfiguration.display_name + ' Professionals' : 'Loading...',
                },
                {
                    href: "/landing",
                    label: "Professionals We Recommend",
                },
                {
                    href: "#",
                    label: this.panelLink ? this.panelLink.panel_configuration.display_name : 'Loading...',
                    active: true,
                },
            ];
        },

        panelLinkId() {
            return this.$route.params.id;
        },

        fixedPrices() {
            if (!this.panelLink) {
                return [];
            }
            return _.filter(this.panelLink.panel_configuration.panel_configuration_pricings, { price_type: 'fixed' });
        },

        hourlyPrices() {
            if (!this.panelLink) {
                return [];
            }
            return _.filter(this.panelLink.panel_configuration.panel_configuration_pricings, { price_type: 'hourly' });
        }
    },

    created() {
        // If no whitelebel configuration, redirect to landing
        if (!this.whitelabelConfiguration) {
            this.$router.push('/');
        }

        this.fetch();
    },

    methods: {
        fetch() {
            let vm = this;
            vm.isLoading = true;
            vm.panelLink = null;
            axios.get(window.API_BASE + '/whitelabel-configurations/' + vm.whitelabelConfiguration.id + '/panel/' + vm.panelLinkId)
                .then(function (response) {
                    vm.panelLink = response.data;
                    vm.isLoading = false;
                })
                .catch(function (error) {
                    vm.isLoading = false;
                    console.log(error);
                    vm.$router.push('/landing');
                });
        }
    }
}
</script>

<style></style>