<template>
    <div>
        <div @click.prevent="openModal" class="card card-hover panel-lawyer-card bg-secondary rounded-3 p-4">
            <div class="left">
                <user-role-avatar class="d-none d-sm-block rounded-circle" :user-role="userRole"
                    width="80"></user-role-avatar>
            </div>
            <div class="right">
                <b>{{ userRole.name }}</b>
                <p class="mt-1 mb-0 pb-1 small">
                    {{ userRole.bio }}
                </p>
            </div>
        </div>

        <vue-final-modal v-model="showModal">
            <div class="modal-dialog" role="document" v-if="userRole">
                <div class="modal-content">
                    <div class="modal-header border-bottom-0">
                        <h5 class="modal-title">
                            Lawyer Details
                            <!--            {{ lawyer.user.name }}-->
                        </h5>
                        <button type="button" class="btn-close" aria-label="Close" @click="closeModal"></button>
                    </div>
                    <div class="modal-body">
                        <bio-section :lawyer="userRole">
                        </bio-section>
                        <hr>
                        <specialities-section :lawyer="userRole"></specialities-section>
                    </div>
                    <!--        <div class="modal-footer">-->
                    <!--          <button type="button" class="btn btn-secondary btn-sm" @click.prevent="$emit('close')">Close</button>-->
                    <!--         -->
                    <!--        </div>-->
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
  
<script>
import Images from "../../mixins/Images";
import BioSection from '../../views/FindALawyer/FindALawyer/Stages/ReviewLawyers/Results/LawyerMoreDetailsModal/BioSection.vue';
import UserRoleAvatar from '../UserRoleAvatar.vue';
import SpecialitiesSection from '../../views/FindALawyer/FindALawyer/Stages/ReviewLawyers/Results/LawyerMoreDetailsModal/SpecialitiesSection.vue';
import _ from 'lodash';

export default {
    name: "PanelLawyerCardAndBio",
    components: { UserRoleAvatar, BioSection, SpecialitiesSection },
    props: ['userRole'],
    mixins: [Images],
    data() {
        return {
            showModal: false
        }
    },
    methods: {
        openModal() {
            this.showModal = true;
        },

        closeModal() {
            this.showModal = false;
        }
    },
    computed: {

    }
}
</script>
  
<style scoped lang="scss">
.panel-lawyer-card {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;

    &:hover {
        cursor: pointer;
    }

    .left {
        width: 100px;
        min-width: 100px;
        flex-shrink: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .right {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
    }
}
</style>